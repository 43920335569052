<template>
  <div class="container">
    <div class="header">
      <div class="header-title">
        <div class="header-title__text">
          {{ blindBoxId ? "编辑盲盒" : "创建盲盒" }}
        </div>
        <div class="header-title__required">必填项</div>
      </div>
      <div class="header-close" @click="goBack">
        <img class="header-close__icon" src="@/assets/img/取消.svg" alt="" />
        <div class="header-close__text">取消</div>
      </div>
    </div>
    <div class="form-box">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="150px"
        class="form"
        size="small"
      >
        <el-form-item label="盲盒名称：" prop="boxName" required>
          <el-input
            v-model.trim="ruleForm.boxName"
            placeholder="请输入盲盒名称"
            :maxlength="25"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="抽奖截止日期：" prop="boxDrawDeadline" required>
          <el-date-picker
            v-model="ruleForm.boxDrawDeadline"
            align="right"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择抽奖截止日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="兑奖截止时间：" prop="boxRedeemDeadline" required>
          <el-date-picker
            v-model="ruleForm.boxRedeemDeadline"
            type="datetime"
            placeholder="请选择兑奖截止时间"
            align="right"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="盲盒类型：" prop="boxType" required>
          <el-select
            v-model="ruleForm.boxType"
            placeholder="请选择盲盒类型"
            @change="onBoxTypeChange"
          >
            <el-option label="企业" :value="0"></el-option>
            <el-option label="个人" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.boxType === 0" label="抽奖企业：" required>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            plain
            :disabled="Boolean(blindBoxId)"
            @click="openAddDialog('添加企业')"
            >添加企业</el-button
          >
          <el-table
            :data="ruleForm.companyList"
            border
            empty-text="点击添加企业"
            style="margin-top: 20px"
          >
            <el-table-column prop="companyName" label="企业名称">
            </el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  :disabled="Boolean(blindBoxId)"
                  @click="ruleForm.companyList.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="奖品：" required>
          <div class="flex flex-a-c">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              plain
              @click="openAddDialog('添加奖品')"
              >添加奖品</el-button
            >
            <div class="prize-limit">最多可添加9个奖品</div>
          </div>
          <el-table
            :data="ruleForm.prizeIdList"
            border
            empty-text="点击添加奖品"
            style="margin-top: 20px"
          >
            <el-table-column prop="prizeName" label="奖品名称">
            </el-table-column>
            <el-table-column label="中奖率(默认10%)">
              <template slot-scope="scope">
                <el-input
                  v-model.number.trim="scope.row.probability"
                  :maxlength="3"
                  placeholder="正整数0~100"
                >
                  <template #suffix>
                    <div style="line-height: 32px">%</div>
                  </template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="ruleForm.prizeIdList.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item
          label="单个用户抽奖次数："
          prop="boxNumber"
          class="box-munber"
        >
          <div class="flex flex-a-c">
            <el-input
              v-model.number.trim="ruleForm.boxNumber"
              type="text"
              style="margin-right: 20px; width: 200px"
              :disabled="isInfinite || Boolean(blindBoxId)"
              :maxlength="25"
              placeholder="请输入抽奖次数"
              @input="boxNumberChange"
            ></el-input>
            <el-checkbox
              v-model="isInfinite"
              :disabled="Boolean(blindBoxId)"
              @change="onInfinite"
              >不限次数</el-checkbox
            >
          </div>
        </el-form-item>
        <el-form-item label="是否直接启用：" prop="isDisable" required>
          <el-radio-group v-model="ruleForm.isDisable">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="submitLoding"
            @click="submitForm('ruleForm')"
            >提 交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <add-dialog
      :selection="ruleForm[formKey]"
      :visible.sync="visible"
      :title="addTitle"
      :data="addData"
      :options="options"
      @close="addClose"
      @search="dialogSearch"
      @changeSelection="changeSelection"
      @handleCurrentChange="handleCurrentChange"
    />
  </div>
</template>
<script>
import addDialog from "./components/addDialog.vue";
import {
  getAwardInfo,
  getFindPageList,
  saveOrUpdateBlindBox,
  getBlindBoxDetail,
} from "@/api/blindBox.js";
export default {
  components: { addDialog },
  data() {
    return {
      ruleForm: {
        isDisable: 0,
        boxNumber: "",
      },
      rules: {
        boxName: [{ required: true, message: "请输入盲盒名称" }],
        boxDrawDeadline: [{ required: true, message: "请选择抽奖截止日期" }],
        boxRedeemDeadline: [{ required: true, message: "请选择兑奖截止时间" }],
        boxType: [{ required: true, message: "请选择盲盒状态" }],
        companyList: [{ required: true, message: "请选择抽奖企业" }],
        prizeIdList: [{ required: true, message: "请选择奖品" }],
        boxNumber: [
          {
            validator: (rule, value, callback) => {
              if (!value && !this.isInfinite) {
                callback(new Error("请输入抽奖次数"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      visible: false,
      addTitle: "",
      isInfinite: false, // 不限次数
      addData: {}, // 添加弹窗表格数据
      options: {
        label: "",
        prop: "",
      },
      formKey: "", // 添加弹窗选择的数据，form的key
      awardInfoList: {
        page: {
          size: 10,
          current: 1,
          total: 100,
        },
        data: [],
      }, // 奖品数据
      findPageList: {
        page: {
          size: 10,
          current: 1,
          total: 100,
        },
        data: [],
      },
      blindBoxId: "",
      submitLoding: false,
    };
  },
  created() {
    this.blindBoxId = this.$route.query?.id || "";
    if (this.blindBoxId) {
      this.getBlindBoxDetail();
    }
  },
  mounted() {
    this.getAwardInfoData();
    this.getFindPageListData();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false;

        console.log(this.ruleForm);
        if (
          this.ruleForm.boxType === 0 &&
          this.ruleForm.companyList.length === 0
        ) {
          return this.$message.error("最少选择一个企业！");
        }
        if (!this.ruleForm.prizeIdList?.length) {
          return this.$message.error("最少选择一个奖品！");
        }
        const data = JSON.parse(JSON.stringify(this.ruleForm));
        data.prizeIdList = data.prizeIdList.map((e) => {
          console.log(e.probability);
          return {
            prizeId: e.prizeId,
            prizeName: e.prizeName,
            probability: e.probability >= 0 ? Number(e.probability) : 10,
          };
        });
        data.companyList =
          data.companyList?.map((e) => {
            return {
              companyId: e.companyId,
              companyName: e.companyName,
            };
          }) || [];
        if (this.isInfinite) data.boxNumber = -1;
        this.submitLoding = true;
        saveOrUpdateBlindBox({ data })
          .then((res) => {
            console.log(res);
            this.submitLoding = false;
            this.$message.success(
              this.blindBoxId ? "编辑成功！" : "创建成功！"
            );
            this.$router.go(-1);
          })
          .catch((e) => {
            this.submitLoding = false;
          });
      });
    },
    /**
     * 获取盲盒详细数据
     */
    getBlindBoxDetail() {
      getBlindBoxDetail({ data: this.blindBoxId }).then((res) => {
        console.log(res);
        if (res.data.companyId) {
          res.data.companyList = [
            {
              companyId: res.data.companyId,
              companyName: res.data.companyName,
            },
          ];
        }
        if (res.data.boxNumber === -1) {
          res.data.boxNumber = "";
          this.isInfinite = true;
        }
        this.ruleForm = res.data;
      });
    },
    resetForm(formName) {
      this.$refs[formName]?.resetFields();
    },
    onInfinite(e) {
      if (e) {
        this.ruleForm.boxNumber = "";
      }
    },
    boxNumberChange(e) {
      if (e) this.isInfinite = false;
    },
    /**
     * 切换盲盒类型
     */
    onBoxTypeChange(e) {
      console.log(e);
      if (e === 1) this.ruleForm.companyList = [];
    },
    goBack() {
      this.ruleForm = {};
      this.resetForm("ruleForm");
      this.$router.go(-1);
    },
    handleCurrentChange(e) {
      console.log(e);
      if (this.formKey === "companyList") {
        this.findPageList.page.current = e;
        this.getFindPageListData();
      } else {
        this.awardInfoList.page.current = e;
        this.getAwardInfoData();
      }
    },
    /**
     * 获取奖品列表
     */
    getAwardInfoData(searchName) {
      console.log(this.awardInfoList);
      getAwardInfo({
        data: {
          isDisable: 0,
          prizeNoOrName: searchName,
        },
        pageNumber: this.awardInfoList.page.current,
        pageSize: this.awardInfoList.page.size,
      }).then((res) => {
        console.log(res);
        this.awardInfoList.page.total = +res.data.total;
        this.awardInfoList.data = res.data.content;
      });
    },
    /**
     * 获取企业列表
     */
    getFindPageListData(searchName) {
      getFindPageList({
        data: {
          isDisable: 0,
          companyName: searchName,
        },
        pageNumber: this.findPageList.page.current,
        pageSize: this.findPageList.page.size,
      }).then((res) => {
        console.log(res);
        this.findPageList.page.total = +res.data.total;
        this.findPageList.data = res.data.content;
      });
    },
    /**
     * 打开添加企业/企业弹窗
     */
    openAddDialog(title) {
      if (title === "添加企业") {
        this.options.label = "企业";
        this.options.prop = "companyName";
        this.options.id = "companyId";
        this.formKey = "companyList"; // companyList
        this.addData = this.findPageList;
      } else {
        this.options.label = "奖品";
        this.options.prop = "prizeName";
        this.options.id = "prizeId";
        this.formKey = "prizeIdList"; // prizeIdList
        this.addData = this.awardInfoList;
      }
      this.addTitle = title;
      this.visible = true;
    },
    dialogSearch(e) {
      console.log("弹窗中的搜索", e);
      if (this.formKey === "companyList") {
        this.getFindPageListData(e);
      } else {
        this.getAwardInfoData(e);
      }
    },
    /**
     * 弹窗确认事件
     */
    changeSelection({ e, isShow }) {
      console.log(e);
      this.$set(this.ruleForm, this.formKey, e);
      isShow && this.addClose();
    },
    addClose() {
      this.visible = false;
      console.log("formkey", this.formKey);
      // this.$refs.ruleForm.validateField(this.formKey);
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/css/base.less";
.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 20px;
  position: relative;
  border-bottom: 1px solid #ededed;
  &::before {
    content: "";
    width: 3px;
    height: 18px;
    position: absolute;
    left: -1px;
    top: 50%;
    border-radius: 2px;
    transform: translateY(-50%);
    background-color: #363f47;
  }
  &-title {
    display: flex;
    align-items: center;
    &__text {
      font-size: 14px;
      color: #000;
      font-weight: 500;
      margin-right: 12px;
    }
    &__required {
      font-size: 12px;
      color: #9a9d9e;
      font-weight: 400;
      position: relative;
      &::before {
        content: "*";
        position: absolute;
        left: -6px;
        color: #e86247;
        font-size: 12px;
      }
    }
  }
  &-close {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__icon {
      width: 14px;
      margin-right: 8px;
    }
    &__text {
      font-size: 14px;
      color: #000;
      font-weight: 400;
    }
  }
}
.form-box {
  padding: 50px 0;
  box-sizing: border-box;
}
.form {
  margin-left: 200px;
  width: 700px;
}
.prize-limit {
  font-size: 12px;
  color: #9a9d9e;
  font-weight: 400;
  margin-left: 10px;
}

::v-deep .cell {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

::v-deep .el-form-item--small .el-form-item__content {
  line-height: 21px;
}
::v-deep .el-radio-group {
  height: 100% !important;
  display: block;
  line-height: 40px !important;
}
.box-munber {
  ::v-deep .el-form-item__label:before {
    content: "*";
    color: #ff4949;
    margin-right: 4px;
  }
}
</style>
