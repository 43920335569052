<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" @open="open">
    <div class="flex search">
      <el-input
        v-model="search"
        class="search__input"
        size="mini"
        placeholder="请输入名称搜索"
      ></el-input>
      <el-button type="primary" size="mini" @click="onSearch">搜 索</el-button>
    </div>
    <el-table
      ref="table"
      :data="pageData.data"
      height="337px"
      border
      :row-key="
        (row) => {
          return row[options.id]
        }
      "
      @select="onSelectData"
      @select-all="onSelectDataAll"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        :property="options.prop"
        :label="`已选择${count}个${options.label}`"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageData.page.current"
      :page-size="pageData.page.size"
      background
      layout="prev, pager, next,  jumper"
      :total="pageData.page.total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="onReset">重 置</el-button>
      <el-button type="primary" size="mini" @click="onConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    selection: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {
        return {
          label: '',
          prop: '',
          id: '',
        }
      },
    },
  },
  data() {
    return {
      count: 0,
      search: '',
      pageData: {
        page: {},
        data: [],
      },
      selectionData: [],
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val.data) {
          console.log(val.data)
          this.pageData = val
          this.defaultSelection()
        }
      },
      deep: true,
      immediate: true,
    },
    selection: {
      handler(val) {
        console.log(val)
        this.selectionData = JSON.parse(JSON.stringify(this.selection)) || []
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.table?.clearSelection()
        this.selectionData = JSON.parse(JSON.stringify(this.selection))
      })
      this.defaultSelection()
    },
    defaultSelection() {
      this.$nextTick(() => {
        // 默认选中
        console.log(this.selectionData)

        this.count = this.selectionData.length
        this.pageData.data.forEach((e, i) => {
          this.selectionData.forEach((row) => {
            if (e[this.options.id] === row[this.options.id]) {
              this.$refs.table?.toggleRowSelection(this.pageData.data[i], true)
            }
          })
        })
      })
    },
    /**
     * 手动处理每条数据是否选中
     * 否则在分页切换后selection是空，导致前面一页已选数据被清空
     */
    onSelectData(selection, row) {
      console.log('执行单条数据处理')
      // 判断this.selectionData中是否存在row
      if (this.selectionData.find((e) => e[this.options.id] === row[this.options.id])) {
        // 存在，则删除row这条数据
        this.selectionData = this.selectionData.filter(
          (e) => e[this.options.id] !== row[this.options.id],
        )
      } else {
        // 不存在，则将row加入this.selectionData
        if (this.selectionData.length >= 9 && this.options.id === 'prizeId') {
          this.$message.error('奖品最多可添加9个')
        }

        this.selectionData.push({ ...row, probability: 10 })
        console.log(this.selectionData)
      }
      // 更新表格选中数量
      this.count = this.selectionData.length
      // 更新父组件已选中数据
      // this.$emit("changeSelection", {
      //   e: JSON.parse(JSON.stringify(this.selectionData)),
      //   isShow: false,
      // });
    },
    onSelectDataAll(selection) {
      // 根据selection是否有数据判断，是全选/取消全选
      if (selection.length) {
        // 全选
        const data = []
        selection.forEach((e) => {
          // 全选数据 ---- 已选数据
          const hove = this.selectionData.find((f) => e[this.options.id] === f[this.options.id])
          // 如果selection中存在未选中的数据，则将其加入到data中
          console.log('全选加入', e)
          if (this.options.id === 'prizeId') {
            !hove && data.push({ ...e, probability: 10 })
          } else {
            !hove && data.push(e)
          }
        })
        this.selectionData = [...this.selectionData, ...data]
      } else {
        // 取消全选
        this.selectionData = this.selectionData.filter((e) => {
          // 当前页的数据 ----- 已选中数据
          const hove = this.pageData.data.find((f) => e[this.options.id] === f[this.options.id])
          // 如果this.selectionData存在hove这条数据，则删除
          return !hove
        })
      }
      // 更新表格选中数量
      this.count = this.selectionData.length
      // 更新父组件已选中数据
      // this.$emit("changeSelection", {
      //   e: JSON.parse(JSON.stringify(this.selectionData)),
      //   isShow: false,
      // });
    },
    onSearch() {
      this.$emit('search', this.search)
    },
    handleCurrentChange(e) {
      this.$emit('handleCurrentChange', e)
    },
    onReset() {
      this.selectionData = []
      this.count = 0
      this.search = ''
      this.$emit('search', '')
      this.$refs.table?.clearSelection()
    },
    onConfirm() {
      console.log(this.options.id)
      console.log(this.selectionData)
      if (this.selectionData.length > 9 && this.options.id === 'prizeId') {
        console.log('不是应该结束了吗')
        return this.$message.error('奖品最多可添加9个')
      }
      this.count = 0
      this.search = ''
      console.log('执行了关闭？')
      this.$emit('changeSelection', {
        e: JSON.parse(JSON.stringify(this.selectionData)),
        isShow: true,
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/css/base.less';
.search {
  margin-bottom: 20px;
  &__input {
    width: 200px;
    margin-right: 20px;
  }
}

::v-deep .el-dialog {
  border-radius: 6px;
  overflow: hidden;
}
::v-deep .el-dialog__header {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  color: #111111;
  font-weight: 400;
  line-height: 1;
}
</style>
